import React from 'react';

//import { Route, Redirect } from "react-router-dom";
import { Route, Navigate } from "react-router-dom";  //upgrade react-router-dom

/*const PrivateRoute = ({component: C, appProps, ...rest}) => {
	return( <Route  {...rest} 
					render= {props => (appProps.user === '') ? <Navigate to="/login" />  //upgrade react-router-dom
				                                             : <C {...props} {...appProps} />}
    />
  );
}*/

//<PrivateRoute exact path="/" component={Main} user=user props={user, setuser} />
const PrivateRoute = ({ user, component }) => {
  return user != '' ? component : <Navigate to="/login" />;
};

export default PrivateRoute;
