import React, { useState } from 'react';
//import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom"; //upgrade react-router-dom

//socketio
//import {socket, API_URL} from './websocket'
import axios from 'axios';
import devPrefix from './devPrefix'

//bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
//import Alert from 'react-bootstrap/Alert'

//sha256 for one additional preliminary hashing
import sha256 from 'crypto-js/sha256';

const Login = (props) => {
	const [valueUser, setvalueUser] = useState('');
	const [valuePW, setvaluePW] = useState('');
	const [valueCode, setvalueCode] = useState('');
	const [valueIncorrect, setvalueIncorrect] = useState(false);
    const push = useNavigate(); //upgrade react-router-dom
	
  /*useEffect((props) => {
	socket.emit('verify', 'abcd');
	socket.on('verify', data => {  console.log(data)	}  )
  }, []);*/
	
  function handleChangeUser
  (event) {
	setvalueUser(event.target.value);
  }
 
  function handleChangePW(event) {
    setvaluePW(event.target.value);
  }

  function handleChangeCode(event) {
    setvalueCode(event.target.value);
  }

  function handleSubmit(event) {
	async function fetchData() {
       try {
	   console.log({event})
	  const result = await axios.post(`${devPrefix}/login`,    //?socketId=${socket.id}
										JSON.stringify({"username": valueUser, "password": sha256(valuePW).toString(),
										"code": valueCode.toString()}),
									  {withCredentials: true,
									   headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
										}
									  }
						);
      props.setuser(result.data);
	  push("/")
	   } catch (err) {
			   console.log(err)
			  if (err.response.status === 401) {setvalueIncorrect(true)}
	   }
    }  
	fetchData()
	event.preventDefault();
  }

	const IncorrectLoginMessage = () => {
		if (valueIncorrect) {return ("Invalid Credentials")}						
	}


	return (
		<div className="App">
			<Container>
				<Row>
					<Col>
					</Col>
					<Col>
						<Form onSubmit={handleSubmit}>
						  <Row>
							<FormControl 
									   placeholder="Username" 
									   value={valueUser} 
									   onChange={handleChangeUser}
                                       isInvalid={valueIncorrect}	/>
						  </Row>
						  <Row>
							<Form.Control type="password" 
										   placeholder="Password"
										   aria-describedby="invalidCred"
										   value={valuePW} 
										   onChange={handleChangePW}
										   isInvalid={valueIncorrect}	/>
							<Form.Control type="password" 
										   placeholder="code"
										   aria-describedby="invalidCred"
										   value={valueCode} 
										   onChange={handleChangeCode}
										   isInvalid={valueIncorrect}	/>
						    <Form.Text id="invalidCred" className="text-danger">
							{IncorrectLoginMessage()}
						  </Form.Text>
						  </Row>					  			  
						  <Row>
							<Button type="submit" >
								Login
							</Button>
						  </Row>
						</Form>
					</Col>
					<Col>
					</Col>
				</Row>
			</Container>
		</div>
	  );
}

export default Login;