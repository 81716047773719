import React, { useState, useEffect } from 'react';
import axios from 'axios';
import devPrefix from './devPrefix'
//import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
//import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
//import Image from 'react-bootstrap/Image'
import logo from './logo2.png'
//import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom"; //upgrade react-router-dom

const Navibar = (props) => {
	const history = useNavigate(); //upgrade react-router-dom
    function handleLogout(event) {
		async function LogOut() {
		  console.log('rrr')
		  const r = await axios.post(`${devPrefix}/logout`, {withCredentials: true,
									   headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
										}});
		  props.setuser({});
		  console.log(r)
		  history.push("/login")
		}  
		LogOut();  
		event.preventDefault();
	  }
	function dlMasterData(event) {
		async function DlMD() {
			const result = await axios.get(`${devPrefix}/downloadmasterdata`, {withCredentials: true,  headers: {'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
																	responseType: "arraybuffer"});
			//console.log(result)
			var blobData = result.data
			var blob = new Blob([blobData], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
			var tempLink = document.createElement('a');
			tempLink.style.display = 'none';
			tempLink.href = blobURL;
			tempLink.setAttribute('download', 'masterdata.xlsx');
			document.body.appendChild(tempLink);
			tempLink.click();
		}  
		DlMD();  
		event.preventDefault();
	  }
	function ulMasterData(event) {
		async function UlMD() {
			//await axios.get(`/uploadmasterdata`, {withCredentials: true});
			const formData = new FormData();
			formData.append("uploadfile", selectedFile);
			try {
				var result = await axios.post(`${devPrefix}/uploadmasterdata`,formData, {withCredentials: true,
														headers: { "Content-Type": "multipart/form-data"},
														}
				); 
			} catch(error) {
			  console.log(error)
			}
		}  
		UlMD();  
		event.preventDefault();
		setShow(false)
	  }	  
  
  
  function rangeValue(event) {
	  props.setrange(event.target.value)
  }
  

  function rangeSTValue(event) {
	  props.setSTrange(props.STrange === 0 ? 1 : 0)
  }

  function setdate1(event) {
	  props.setdate1(event.target.value)
  }

  function setdate2(event) {
	  props.setdate2(event.target.value)
  }
  
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [selectedFile, setSelectedFile] = React.useState(null);
   const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
   }
  
  return (
            <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark" sticky="top" className="justify-content-between"> 
			  <Navbar.Brand href="/"><img  className="srlogo"  src={logo} alt="Searover logo"  /></Navbar.Brand>
			  <Navbar.Toggle aria-controls="basic-navbar-nav" />
			  <Navbar.Collapse id="basic-navbar-nav">
			   <Nav className="mr-auto">
			   </Nav>
			  
			  
			  <Nav>
			   <Form className="d-inline-flex px-1" >


					<Form.Control size="sm" type="date" name='date1' value={props.date1} onChange={setdate1} />

					<Form.Control size="sm" type="date" name='date2' value={props.date2} onChange={setdate2}/>

				</Form>
			   </Nav>
			  
			  <Button variant={props.STrange === 0 ? "primary" : "light"} onClick={rangeSTValue}>ST</Button>
			  <Nav>
			   <Form className="d-inline-flex px-1" >
					<Form.Text>{props.range} years </Form.Text>
					<Form.Control type="range" onChange={rangeValue} min={1} max={5} value={props.range} />

				</Form>
			   </Nav>
				<Button onClick={dlMasterData}>MstDt DnLoad</Button>
				<Button onClick={handleShow}>Upld</Button>
			   <Nav>
				<NavDropdown title="Account" id="nav-dropdown">
					<NavDropdown.Item as="p" className="Navibar-text">Logged in as</NavDropdown.Item>
					<NavDropdown.Item as="p" className="Navibar-user">{props.user.user}</NavDropdown.Item>
					<NavDropdown.Item href="#profile">Profile</NavDropdown.Item>
					<NavDropdown.Divider />
					<NavDropdown.Item as="button" onClick={handleLogout}>Log Out</NavDropdown.Item>
				</NavDropdown>
			   </Nav>
			  </Navbar.Collapse>	  
				<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
				  <Modal.Title>Upload File</Modal.Title>
				</Modal.Header>
				<Modal.Body>Click Browse to select a file and then click Upload
					<Form onSubmit={ulMasterData}  method="POST" encType="multipart/form-data">
						<Form.Control type="file" name="uploadfile" onChange={handleFileSelect}/>
						<Button variant="primary" type="submit">
							Upload
						</Button>
					</Form>
				</Modal.Body>
			  </Modal>
			</Navbar>)
}

export default Navibar;