
import React, { useState, useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';
//socketio
//import {socket, API_URL} from './websocket'
import axios from 'axios';
import devPrefix from './devPrefix'
import Navibar from './Navibar'

//bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
//import Navbar from 'react-bootstrap/Navbar'
//import Nav from 'react-bootstrap/Nav'
//import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Table from 'react-bootstrap/Table'
//import Dropdown from 'react-bootstrap/Dropdown'*/
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'

import { VictoryChart, VictoryAxis, VictoryCandlestick, VictoryLine,VictoryCursorContainer,
VictoryLabel, VictoryBar, Selection, VictoryScatter} from 'victory'; 

import yahoologo from './yahoo.jpg'
import avlogo from './AV_400x400.jpg'

// initialize the list of colors for the char lines
import variables from './Sass/App.scss'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTimesCircle,faSave, faSortAmountUp } from '@fortawesome/free-solid-svg-icons'

import Index from './Index_comp'

import Maingraph from './Maingraph'
import MaingraphAdj from './MaingraphAdj'

var colorlist = []
for (var key in variables) {
colorlist.push(variables[key])
}

const Main = (props) => {
	const [range, setrange] = useState(5);
	const [STrange, setSTrange] = useState(0);
	const [data, setdata] = useState([]);
	const [assets, setassets] = useState([]);

	var d1 = new Date();
	var d2 = new Date();
	d1.setDate(d1.getDate() - 7);
	d2.setDate(d2.getDate() - 365);
	
	const [date1, setdate1] = useState(d1.toISOString().slice(0, 10));
	const [date2, setdate2] = useState(d2.toISOString().slice(0, 10));
	
	const [sortfield, setsortfield] = useState('trendline');

	async function AllIndices() {
	  try {
		  const result = await axios.get(`${devPrefix}/allindices?date1=${date1}&date2=${date2}`, {withCredentials: true});
		  //console.log(result.data)
		  result.data.map(obj=> ({ ...obj, showgraph: false }))
		  setdata(result.data);
	  } catch (err) {
		  console.log(err)
		}
	}  
	
	useEffect(() => {
		AllIndices();  
		async function AllAssets() {
		  try {
			  const result = await axios.get(`${devPrefix}/assets?date1=${date1}&date2=${date2}`, {withCredentials: true});
			  //console.log(result.data)
			  setassets(result.data);
			  //setassetlist(result.data.map(asset => (asset.indxsym)))
		  } catch (err) {
			  console.log(err)
			}
		}
		AllAssets();
	  }, []);	
	
	
	useEffect(() => {
		AllIndices();
	  }, [date1, date2]);
	
	useEffect(() => {
		dispatchind({type: 'INIT'})	
	  }, [data]);	

	const mainReducer = (state, action) => { 
	   //console.log(action)
	  switch (action.type) {
		case 'INIT': 
			return {indexes:data}
		case 'UPD_IND': 
		  return {indexes:state.indexes.map(ind => {
			  //console.log(action, ind)
			if (ind.id == action.key) {
			  return { ...ind, sorter: action.sorter, field10: action.field10 };
			} else {
			  return ind;
			}
		  })};
		case 'UPD_IND_CARDCLASS': 
		  return {indexes:state.indexes.map(ind => {
			  //console.log(action, ind)
			if (ind.id == action.key) {
			  return { ...ind, /*cardclass:action.cardclass,*/ showgraph:action.showgraph };
			} else {
			  return ind;
			}
		  })};
		default:
		  return state;
	  }
	};

	const [indexes, dispatchind] = useReducer(mainReducer, {indexes:[]});	
	
	function setsortf (e) {e.preventDefault();setsortfield(e.target.id)}
	
	function cmprind(a, b) {
	  let comparison = 0;
	  switch (sortfield) {
		case 'trendline':
			if (a.sorter > b.sorter) {comparison = -1;} 
			else if (a.sorter < b.sorter) {comparison = 1;}
			else {
				if (a.symbol > b.symbol) {comparison = 1;}
				else {comparison = -1;}
			}
			break;
		case 'date1':
			if (a.close_delta_adhoc1 > b.close_delta_adhoc1) {comparison = -1;} 
			else if (a.close_delta_adhoc1 < b.close_delta_adhoc1) {comparison = 1;}
			else {
				if (a.symbol > b.symbol) {comparison = 1;}
				else {comparison = -1;}
			}
			break;			
		case 'date2':
			if (a.close_delta_adhoc2 > b.close_delta_adhoc2) {comparison = -1;} 
			else if (a.close_delta_adhoc2 < b.close_delta_adhoc2) {comparison = 1;}
			else {
				if (a.symbol > b.symbol) {comparison = 1;}
				else {comparison = -1;}
			}
			break;				
	  }
	  return comparison;
	}
	
  return (<div className="Main">
  <Navibar user={props.user} setuser={props.setuser} range={range} setrange={setrange} STrange={STrange} setSTrange={setSTrange} date1={date1} setdate1={setdate1} date2={date2} setdate2={setdate2} />
	<Container fluid>
		<Row>
			<Col sm={12} md={4}>
				<Carousel className="MaingraphCrsl" interval={null} >
				  <Carousel.Item>
					<Maingraph />
					<Carousel.Caption>
					  <h3>Unadjusted</h3>
					  <p>Absolute value Portfolio</p>
					</Carousel.Caption>
				  </Carousel.Item>
				  <Carousel.Item>
					<MaingraphAdj />
					<Carousel.Caption>
					  <h3>Adjusted</h3>
					  <p>Relative value adjusted for Debits/Credits</p>
					</Carousel.Caption>
				  </Carousel.Item>
				</Carousel>
				<Table striped bordered hover responsive  size="sm">
				  <thead>
					<tr>
					  <th>Asset</th>
					  <th>Cost</th>
					  <th>Val(divs)</th>
					  <th>%</th>
					  <th>Ann%</th>
					</tr>
				  </thead>
				  <tbody>
				  {assets.map(function(asset, index){
					if (asset.asset ==='TOTAL') { return (
						<React.Fragment key={asset.asset}>
						<tr className="bold">
						  <td></td>
						  <td>{asset.avg_cost}</td>
						  <td>{asset.value} ({asset.dividend})</td>
						  <td>{asset.actual_delta}</td>
						  <td>{asset.annualized}</td>
						</tr></React.Fragment>				  
					)};	
					})
					}
				  {assets.map(function(asset, index){
					if (asset.asset !='TOTAL') { return (
						<React.Fragment key={asset.asset}>
						<tr>
						  <td>{asset.asset}</td>
						  <td>{asset.avg_cost}</td>
						  <td>{asset.value} ({asset.dividend})</td>
						  <td>{asset.actual_delta}</td>
						  <td>{asset.annualized}</td>
						</tr></React.Fragment>				  
					)};	
					})
					}
				  </tbody>
				</Table>
			</Col>			
			<Col sm={12} md={4}>
				<Card><Card.Body className="overview">
					<Table borderless className="overview"><tbody className="overview">
					<tr>
						<td className ="lstats">
							Security
						</td>
						<td className="stats">
							{
							   (() => {
									var days = parseInt((new Date()- new Date(date1))/(1000 * 60 * 60 * 24),10)
									var title
									if (days > 27) {title = parseInt(days/30.44) + "M"} 
									     else {title = days + "d"}
									if (sortfield==="date1")
										{return (<span>{title}<FontAwesomeIcon icon={faSortAmountUp} /></span>
										)}
									else {
									  return <a id="date1" onClick={setsortf}>
									  {title}</a>
									}
							   })()
							}
						</td>	
						<td className="stats">
							{
							   (() => {
									var days = parseInt((new Date()- new Date(date2))/(1000 * 60 * 60 * 24),10)
									var title
									if (days > 27) {title = Math.round(days/30.44) + "M"} 
									     else {title = days + "d"}
									if (sortfield==="date2")
										{return (<span>{title}<FontAwesomeIcon icon={faSortAmountUp} /></span>
										)}
									else {
									  return <a id="date2" onClick={setsortf}>
									  {title}</a>
									}
							   })()
							}
						</td>											
						<td className="stats">
							   {
								   (() => {
									   if (sortfield==="trendline")
										  return <span>T<FontAwesomeIcon icon={faSortAmountUp} /></span>
										else {
										  return <a id="trendline" onClick={setsortf}>
												T</a>	
										}
								   })()
								}							
						</td>	
					</tr>
				</tbody></Table>
			</Card.Body></Card>
			
				{indexes.indexes.sort(cmprind).map(function(ind, index){
					 return( <Indexcard key={ind.id} 
										indcardclass={ind.cardclass}
										indsymbol={ind.symbol}
										indclose_delta_adhoc1={ind.close_delta_adhoc1}
										indclose_delta_adhoc2={ind.close_delta_adhoc2}
										indsorter={ind.sorter}
										indid={ind.id}
										dispatchind={dispatchind}/>
							)
					})
				}
			</Col>
			<Col sm={12} md={4}>
					{indexes.indexes.sort(cmprind).map(function(ind, index){
					if (ind.showgraph) { return (<Index key={ind.id} ind={ind} range={STrange === 1 ? .5 : range} dispatchind={dispatchind}
					/>)};	
					})
					}
			</Col>
		</Row>

	</Container> </div>)
}


const Indexcard = React.memo((props) => {
	const [cardclass, setcardclass] = useState(props.indcardclass);
	//var cardclass
	function togglecardclass (event) {
		switch(cardclass) {
			case "owned":
				props.dispatchind({type: 'UPD_IND_CARDCLASS', key:props.indid, /*cardclass:"ownedselected",*/ showgraph: true});
				setcardclass("ownedselected");
				//cardclass = "ownedselected"
				break;
			case "ownedselected":
				props.dispatchind({type: 'UPD_IND_CARDCLASS', key:props.indid, /*cardclass:"owned",*/ showgraph: false});
				setcardclass("owned");
				//cardclass = "owned"
				break;
			case "notowned":
				props.dispatchind({type: 'UPD_IND_CARDCLASS', key:props.indid, /*cardclass:"notownedselected",*/ showgraph: true});
				setcardclass("notownedselected");
				//cardclass = "notownedselected"
				break;
			case "notownedselected":
				props.dispatchind({type: 'UPD_IND_CARDCLASS', key:props.indid, /*cardclass:"notowned",*/ showgraph: false});
				setcardclass("notowned");
				//cardclass = "notowned"
				break;
		}
	}

	return (
			<Card><Card.Body className={props.indcardclass} onClick={togglecardclass}>
				<Table borderless className={props.indcardclass} ><tbody className={cardclass}>
					<tr>
						<td className="secur">
							{props.indsymbol}
						</td>
						<td className={props.indclose_delta_adhoc1 >= 0 ? "pos" : "neg"}>
							{props.indclose_delta_adhoc1}
						</td>	
						<td className={props.indclose_delta_adhoc2 >= 0 ? "pos" : "neg"}>
							{props.indclose_delta_adhoc2}
						</td>											
						<td className={props.indsorter >= 0 ? "pos" : "neg"}>
							{props.indsorter === -Infinity ? "-" : props.indsorter }
						</td>	
					</tr>
				</tbody></Table>
			</Card.Body></Card>
	)
})

export default Main;