import React, { useState, useEffect } from 'react';

/*
//fontawesome
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
//import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
*/

import { BrowserRouter as Router, Route,Routes, /*Link, Redirect*/ } from "react-router-dom";
//socketio
/*
import {socket, API_URL} from './websocket'
*/
import axios from 'axios';
import devPrefix from './devPrefix'

//bootstrap
//import Container from 'react-bootstrap/Container'
//import Row from 'react-bootstrap/Row'
//import Col from 'react-bootstrap/Col'
//import Card from 'react-bootstrap/Card'
//import Navbar from 'react-bootstrap/Navbar'
//import Nav from 'react-bootstrap/Nav'
//import NavDropdown from 'react-bootstrap/NavDropdown'
//import Form from 'react-bootstrap/Form'
//import FormControl from 'react-bootstrap/FormControl'
//import Dropdown from 'react-bootstrap/Dropdown'*/
//import Button from 'react-bootstrap/Button'

//Local imports
import PrivateRoute from './PrivateRoute'
import Login from './Login'
import Main from './Main'

const App = () => {
	const [user, setuser] = useState(null);
    
	useEffect(() => {
		async function CheckAuth() {
		  try {
			  //const result = await axios.get(`${API_URL}/checkauth`, {withCredentials: true});
			  const result = await axios.get(`${devPrefix}/checkauth`, {withCredentials: true});
			  setuser(result.data);
		  } catch (err) {
			  if (err.response.status === 401) {setuser('')}
			}
		}  
		CheckAuth();  
	  }, []); //<PrivateRoute exact path="/" component={Main} appProps={{ user, setuser }} />
	  //<Route path="/" element={<PrivateRoute exact path="/" component={<Main />} user=user props={user, setuser} />}/>
	
	if (user == null) return (<div>Loading ...</div>)
	return (
			<Router><Routes>
				<Route path="/" element={<PrivateRoute exact path="/" component={<Main user={user} setuser={setuser} />} user={user} props={user, setuser} />}/>
				<Route path="/login" element={<Login user={user} setuser={setuser} />} />
			</Routes></Router>
		)
}

export default App;
